export default function Nav({ setActivePage, activePage }) {
    return (
        <nav>
            <ul className="navbar tracking-in-expand">
                <li onClick={() => setActivePage("About")} className={activePage==="About"?"active":""} >👋 About</li>
                <li onClick={() => setActivePage("Skills")}  className={activePage==="Skills"?"active":""} >👨‍💻 Skills</li>
                {/* <li onClick={() => setActivePage("Projects")}  className={activePage==="Projects"?"active":""} >✨ Projects</li> */}
                <li  className={activePage==="Activity"?"active":""}  onClick={() => setActivePage("Activity")} >🎶 Vibe</li>
            </ul>
        </nav>

    )
}