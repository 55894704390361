import Cover from "../Cover"
import Profile from "../Profile"
import ProfileInfo from "../ProfileInfo"
import Sound from 'react-sound';
import ladi from '../../assets/musics/Yomari Sessions III_ _Naya_ by Ladi feat. Bickey Adhikari.mp3'

import LowerComp from "../LowerComp"
export default function Main({setEmptyComp,alreadyPlaying,setAlreadyPlaying}) {
    return (

        <div className="main-container" onClick={()=>{
            if(!alreadyPlaying){
                setEmptyComp(<Sound
                    url={ladi}
                    playStatus={Sound.status.PLAYING}
                    playFromPosition={5000 /* in milliseconds */}
                    volume={50}
        
                />
                )
                setAlreadyPlaying(true)
                }
        }}>
            <div className="all-contents">
                <div className="upper-part">
                    <Cover />
                    <Profile />
                    <ProfileInfo/>
                    <LowerComp />
                </div>
            </div>
        </div>
    )
}