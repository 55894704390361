export default function About() {
    return (
        <p className="spotify-container">
            <div className="song-info fade-in">


                <p className="about">
                    I am Dinesh Tiwari , a web developer with a passion for learning new things. 
                    I am a self taught programmer and i belive i have decent skills with ReactJS , Python , Solidity , Web3.js and I am also familiar with Backend Frameworks like [Flask , FastAPI] .
                    I like working with MongoDB for the Backend Projects with database involved. I love exploring new frameworks and technologies so i am forever evolving and sharping my skills.
                    I am fascinated by web3 and blockchain technologies .
                </p>




            </div>
        </p>
    )

}