

import HypeSquad from "../../assets/images/svg1.svg"
import NITRO from "../../assets/images/svg2.svg"
import BOOST from "../../assets/images/svg3.svg"
export default function Icons({isMobile}) {
    return (
        < div className={ isMobile ? "profile-icons-mobile scale-in-center" :"profile-icons scale-in-center"} >
            <div className="tooltip">
                <img src={HypeSquad} alt="" />
                <span className="tooltiptext">HypeSqual Dunno What FOR</span>
            </div>

            <div className="tooltip">
                <img src={NITRO} alt="" />
                <span className="tooltiptext">Can't Afford in Discord but can afford here</span>
            </div>

            <div className="tooltip">
                <img src={BOOST} alt="" />
                <span className="tooltiptext">Boost bc i like the icon</span>
            </div>
        </div >
    )
}