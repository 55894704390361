
import DND  from "../../assets/images/active-status.webp"
import Icons from "../Icons"
import dp from '../../assets/images/dp.png'
export default function Profile() {
    return (
        <div className="user-profile">
            <div className="profile-img scale-in-center">
                <img src={dp}
                    alt="" />
                <img src={DND} alt="" />
            </div>

            <Icons isMobile={false}/>

            <button className="donate-btn">Hire Me</button>
        </div>
    )
}