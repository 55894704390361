
import Nav from "../Nav"
import Spotify from "../Pages/Spotify"
import About from "../Pages/About";
import Skills from "../Skills";
import { useState,useEffect } from "react";

export default function LowerComp() {
    const [activePage,setActivePage] = useState("About");
    let maps = {
        "About": <About />,
        "Activity": <Spotify />,
        "Skills": <Skills />
    }

    useEffect(() => {
        console.log("activePage",activePage);
    },[activePage]);

    return (
        <div className="lower-part">
            <Nav setActivePage={setActivePage} activePage = {activePage} />
            <div className="divider"></div>

            <div className="show-section">


                {maps[activePage]}
               
            </div>
        </div>

    )
}