import './App.css';
import Main from './Components/Main';

import { useState } from 'react';
function App() {

  const [emptyComp, setEmptyComp] = useState(<></>);
  const [alreadyPlaying, setAlreadyPlaying] = useState(false);

  return (
    <>
      {emptyComp}

      < Main setEmptyComp={setEmptyComp} alreadyPlaying={alreadyPlaying} setAlreadyPlaying={setAlreadyPlaying} />
    </>

  );
}

export default App;
