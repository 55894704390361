
import reactLogo from '../../assets/images/react.png';
import pythonLogo from '../../assets/images/python.png';
import solidityLogo from '../../assets/images/solidity-trans.png';
import javascriptLogo from '../../assets/images/javascript.png';
export default function Skills() {
    return (
        <div className="skills-section">

            <div className="all-skills">
                <div className="skill fade-in">
                <img src={reactLogo} alt="" className="scale-in-center-copy" />
                    <div className="skill-info">
                        <h3>ReactJS</h3>
                    </div>
                </div>
                <div className="skill  fade-in">
                <img src={pythonLogo} alt="" className="scale-in-center-copy" />
                    <div className="skill-info">
                        <h3>Python</h3>
                    </div>
                </div>
                <div className="skill">
                    <img src={solidityLogo} alt="" className="scale-in-center-copy" />
                    <div className="skill-info">
                        <h3>Solidity</h3>
                    </div>
                </div>
                <div className="skill">
                    <img src={javascriptLogo} alt="" className="scale-in-center-copy" />
                    <div className="skill-info">
                        <h3>JavaScript</h3>
                    </div>
                </div>
            </div>
        </div>

    )

}