

import Icons from "../Icons"

export default function ProfileInfo() {
    return (
        <div className="user-info">
            <h2 className="fade-in">Dinesh Tiwari <span>#8848</span></h2>
            <Icons isMobile={true}/>

            <p className="fade-in">Backend Developer / Web3 Developer </p>
           
        </div>
          
    )
}